<template>
    <div>
        <Toast />
        <div class="card">
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>{{$t('Inquiry.search.searchHeading')}}</h5>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3">
                            <label for="type">{{$t('Inquiry.search.InquiryTitle')}}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('Inquiry.search.SearchbyTitle')"
                                v-model="inquiry_title" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="type">{{$t('Inquiry.search.InquiryID')}}</label>
                            <InputText id="googlurl" type="text" :placeholder="$t('Inquiry.search.SearchbyID')" v-model="applicant_id" />
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{$t('Inquiry.search.InquiryStatus')}}</label>
                            <Dropdown v-model="status" :options="dropdownstatusValues" optionValue="value"
                                optionLabel="name" :placeholder="$t('Inquiry.search.Select')" />
                        </div>

                        <!-- <div class="p-field p-col-12 p-md-3">
                            <label for="pass">Inquiry Category</label>
                            <Dropdown v-model="category_id" :options="dropdownBoardCategoryValues" optionValue="id"
                                optionLabel="title" placeholder="Select " @click="getCategory" />
                        </div> -->
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{$t('Inquiry.search.UserType')}}</label>
                            <Dropdown v-model="user_type" :options="dropdownUserTypeValues" optionValue="value"
                                optionLabel="name" :placeholder="$t('Inquiry.search.Select')" />
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{$t('Inquiry.search.ReplierName')}}</label>
                            <Dropdown v-model="replier_email" :options="dropdownEmailValues" optionValue="email"
                                optionLabel="email" :placeholder="$t('Inquiry.search.Select')" />
                        </div>

                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{$t('Inquiry.search.InquiryDate&Time')}}</label>
                            <Calendar inputId="range" v-model="InqdateRange" selectionMode="range" :manualInput="false"
                                placeholder="YYYY/MM/DD - YYYY/MM/DD" :showIcon="true"></Calendar>
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <label for="pass">{{$t('Inquiry.search.ReplyDate')}}</label>
                            <Calendar inputId="range" v-model="ReplydateRange" selectionMode="range"
                                :manualInput="false" placeholder="YYYY/MM/DD - YYYY/MM/DD" :showIcon="true"></Calendar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-jc-between p-ai-lg-center p-ai-start p-mt-6 p-flex-column p-flex-lg-row">
                <div class="p-mb-4 p-mb-lg-0"></div>
                <div>
                    <Button :label="$t('button.search')" icon="pi pi-search" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2"
                        @click="searchInquiry()"></Button>
                    <Button :label="$t('button.reset')" icon="pi pi-replay" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2"
                        @click="resetInquiry()"></Button>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-d-flex p-jc-between p-mb-2">
                        <h4>{{$t('Inquiry.list.Heading')}}</h4>
                        <!-- <div>
                            <router-link to="/BulletinManagementAdd">
                                <Button label="Primary" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2"
                                    style="background: black; color: white">Add Bulletin Management </Button>
                            </router-link>
                        </div> -->
                    </div>
                    <DataTable :value="Inqueries" :paginator="true" class="p-datatable-gridlines" :rows="10"
                        dataKey="id" :rowHover="true" :loading="loading1" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                        <ConfirmDialog group="dialog" />

                        <template #empty>{{$t('Nodata')}}</template>
                        <template #loading>Loading data. wait.</template>

                        <Column field="#" header="#" style="min-width: 5rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Sl. No.</span>
                                {{ data.sl_no }}
                            </template>
                        </Column>
                        <Column field="ID" header="ID" style="min-width: 5rem">
                            <template #body="{ data }">
                                <span class="p-column-title">ID</span>
                                {{ data.applicant_id === null ? '---' : data.applicant_id }}
                            </template>
                        </Column>
                        <Column field="Inquiry Title" :header="$t('Inquiry.list.InquiryTitle')" style="min-width: 13rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Inquiry Title</span>
                                {{
                                    data.title === null ? '---' : data.title
                                }}
                            </template>
                        </Column>
                        <!-- <Column field="Inquiry Category" header="Inquiry Category" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Inquiry Category</span>
                                {{ data.type_title }}
                            </template>
                        </Column> -->
                        <Column field="Inquiry By" :header="$t('Inquiry.list.InquiryBy')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Inquiry By</span>

                                {{ data.inquiry_by }}
                            </template>
                        </Column>
                        <Column field="User Type" :header="$t('Inquiry.list.UserType')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">User Type</span>

                                {{ data.userType }}
                            </template>
                        </Column>
                        <Column field="Answer Status" :header="$t('Inquiry.list.AnswerStatus')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Answer Status</span>

                                {{ data.status === 'answered' ? 'Responded' : 'Pending' }}
                            </template>
                        </Column>

                        <Column field="Inquiry Date & Time" :header="$t('Inquiry.list.InquiryDate&Time')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Inquiry Date & Time</span>
                                {{ startdateformat(data.createdDate) }}
                            </template>
                        </Column>
                        <Column field="Replier Details" :header="$t('Inquiry.list.ReplierDetails')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Replier Details</span>

                                {{ data.reply_by === null ? '---' : data.reply_by }}
                            </template>
                        </Column>
                        <Column field="Reply Date & Time" :header="$t('Inquiry.list.ReplyDate&Time')" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span class="p-column-title">Reply Date & Time</span>
                                {{ data.updatedDate === null ? '---' : startdateformat(data.updatedDate) }}
                            </template>
                        </Column>

                        <Column field="Actions" :header="$t('Inquiry.list.Action')">
                            <template #body="{ data }">
                                <span class="p-column-title">Actions</span>
                                <p style="display: none">{{ data.status }}</p>
                                <div style="display: flex">
                                    <span v-if="data.status == 'not_answered'">
                                        <router-link :to="'/Manager/Inquiry/ViewInquiry/' + data.id">
                                            <Button label="help" class="p-button-outlined p-button-help p-mb-2">
                                                <i class="pi pi-reply p-mr-2"></i>
                                            </Button>
                                        </router-link>
                                    </span>
                                    <span v-else-if="data.status == 'answered'">
                                        <router-link :to="'/Manager/Inquiry/InquiryDetails/' + data.id">
                                            <Button label="help" class="p-button-outlined p-button-help p-mb-2">
                                                <i class="pi pi-reply p-mr-2"></i>
                                            </Button>
                                        </router-link>
                                    </span>
                                    <Button icon="pi pi-trash"
                                        class="n-wrap p-button-danger p-button-outlined p-mb-2"
                                        @click="deleteNote(data.id)" />

                                    <!-- <Button :label="$t('button.delete')" icon="pi pi-trash" class="n-wrap p-button-danger p-button-outlined p-mr-2 p-mb-2" @click="confirm(data.id)" /> -->
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { useRoute } from 'vue-router';
import InquiryService from '../../../service/API/SuperAdmin/InquiryService.js';
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            // dropdownValues: [{ name: 'nft',code:'NFT' }, { name: 'card_news',code:'Card News' }, { name: 'media_press',code:'Media press' }, { name: 'de_fi_services',code:'De-Fi Services' }],
            dropdownstatusValues: [
                { name: '해결됨', value: 'answered' },
                { name: '미해결', value: 'not_answered' },
            ],
            serial: 0,
            dropdownstatusValue: null,
            dropdownUserTypeValues: [
                { name: '모델 유저', value: 'model' },
                { name: '일반 유저', value: 'general' },
            ],
            dropdownUserTypeValue: null,

            isModalVisible: false,
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            dropdownBoardCategoryValues: '',
            dropdownBoardCategoryValue: null,
            products: null,
            loading1: true,
            deletedID: null,
            sl_no: '',
            title: '',
            country_id: '',
            category_id: '',
            status: '',
            total: '',
            id: '',
            description: '',
            Inqsearchdate: '',
            Inqsearchenddate: '',
            InqdateRange: '',
            Replysearchdate: '',
            Replysearchenddate: '',
            ReplydateRange: '',
            Inqueries: '',
            inquiry_title: '',
            applicant_id: '',
            user_type: '',
            type_id: '',
            inquiry_date_from: '',
            inquiry_date_to: '',
            reply_date_from: '',
            reply_date_to: '',
            dropdownEmailValues:'',
            dropdownEmailValue:null,
            replier_email:''
        };
    },
    created() {
        this.inquiryService = new InquiryService();
    },
    mounted() {
        const route = useRoute();
        console.log(route.params);
        // this.getCategory()
        this.getInqlist();
        

        this.inquiryService
            .getReplierEmaildropdown()
            .then((data) => {
                this.dropdownEmailValues = data.data.data.reply_email;
                // this.products = data;
                this.loading1 = false;
                // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
                console.log(this.dropdownCountryValues);
            })
            .catch((err) => console.log(err));
    },
    methods: {
        getInqlist() {
            const InquiryListParams = {
                // page: 1,
                // limit: this.pagination.pagination_limit,
                inquiry_title: this.inquiry_title,
                applicant_id: this.applicant_id,
                user_type: this.user_type,
                type_id: this.type_id,
                inquiry_date_from: this.Inqsearchdate,
                inquiry_date_to: this.Inqsearchenddate,
                reply_date_from: this.Replysearchdate,
                reply_date_to: this.Replysearchenddate,
                status : this.status,
                replier_email:this.replier_email

            };
            this.loading1 = true;
            this.inquiryService
                .getInquiryList(InquiryListParams)
                .then((res) => {
                    this.Inqueries = res.data.data.inquery;
                    this.totalrecords = res.data.total_records;
                    // this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                    this.loading1 = false;
                    console.log(res);
                })
                .catch((err) => console.log(err));
        },

        getCategory() {

            this.bulletinManagementService.getBoardCategorydropdown(this.country_id).then((res) => {

                this.dropdownBoardCategoryValues = res.data.data.board_category;
                console.log(res);
            });
        },
        addDay(val) {
            const date = new Date(val);
            var dd = date.getDate();
            var mm = date.getMonth() + 1;
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return (val = yyyy + '-' + mm + '-' + dd);
        },

        resetInquiry() {
            this.inquiry_title = '';
            this.status = '';
            // this.category_id = '';
            this.ReplydateRange = '';
            this.InqdateRange = '';
            this.applicant_id = '';
            this.user_type = '';
            this.status = '';
            this.replier_email = '';
            // this.calendarValue1 = '';
            this.loading1 = true;
            // this.getInqlist();
            window.location.reload();
        },

        searchInquiry() {
            if (this.inquiry_title === '' && this.status === '' && this.ReplydateRange === '' && this.InqdateRange === '' && this.user_type === '' && this.applicant_id === '' && this.replier_email === '') {
                this.$toast.add({ severity: 'error', summary: '에러 메시지', detail: '검색창을 입력해주세요.', life: 2000 });
            } else {
                if (this.InqdateRange.at(0) != '') {
                    this.Inqsearchdate = this.formatRangeDate(this.InqdateRange.at(0));
                } else {
                    this.Inqsearchdate = '';
                }
                if (this.InqdateRange.at(1) != '') {
                    this.Inqsearchenddate = this.formatRangeDate(this.InqdateRange.at(1));
                } else {
                    this.Inqsearchenddate = '';
                }
                if (this.ReplydateRange.at(0) != '') {
                    this.Replysearchdate = this.formatRangeDate(this.ReplydateRange.at(0));
                } else {
                    this.Replysearchdate = '';
                }
                if (this.ReplydateRange.at(1) != '') {
                    this.Replysearchenddate = this.formatRangeDate(this.ReplydateRange.at(1));
                } else {
                    this.Replysearchenddate = '';
                }
                const SearchInqParams = {
                    // page: 1,
                    // limit: this.pagination.pagination_limit,
                    inquiry_title: this.inquiry_title,
                    applicant_id: this.applicant_id,
                    user_type: this.user_type,
                    type_id: this.type_id,
                    inquiry_date_from: this.Inqsearchdate,
                    inquiry_date_to: this.Inqsearchenddate,
                    reply_date_from: this.Replysearchdate,
                    reply_date_to: this.Replysearchenddate,
                    status:this.status,
                    replier_email:this.replier_email
                };
                this.inquiryService
                    .getInquiryList(SearchInqParams)
                    .then((res) => {
                        this.Inqueries = res.data.data.inquery;
                        // this.total = res.data.data.total;
                        this.loading1 = false;
                        console.log(res);
                    })
                    .catch(() => {
                        this.Inqueries = [];
                        this.loading1 = false;
                    });
            }
        },

        dateformat(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },

        startdateformat(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD h:m:s ');
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },

        deleteNote: function (id) {
            console.log(id);
            var self = this;
            this.$confirm.require({
                group: 'dialog',
                header: '삭제',
                message: '정말로 삭제하시겠습니까?',
                icon: 'pi pi-trash',
                acceptLabel: '삭제',
                rejectLabel: '취소',
                accept: () => {
                    axios({ method: 'delete', url: '/asnhist-dev/api/v1/sites/inquery/delete', data: { id: id } }).then(function (response) {
                        console.log(response);
                       self.getInqlist();
                    });
                    this.$toast.add({ severity: 'info', summary: '삭제되었습니다.', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                },
                // reject: () => {
                //     this.$toast.add({ severity: 'error', summary: 'An error occurred', detail: 'You have rejected', life: 3000 });
                // },
            });
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #607d8b;
    }
}

::v-deep(.p-datatable .p-sortable-column .p-column-title) {
    display: block;
}

.p-datatable .p-column-filter {
    display: none;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-qualified {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-unqualified {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-negotiation {
        background: #feedaf;
        color: #8a5340;
    }

    &.status-new {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-renewal {
        background: #eccfff;
        color: #694382;
    }

    &.status-proposal {
        background: #ffd8b2;
        color: #805b36;
    }
}

.p-progressbar-value.ui-widget-header {
    background: #607d8b;
}

@media (max-width: 640px) {
    .p-progressbar {
        margin-top: 0.5rem;
    }
}

.product-image {
    width: 100px;
    height: 50px;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.orders-subtable {
    padding: 1rem;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}

.order-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.order-delivered {
        background: #c8e6c9;
        color: #256029;
    }

    &.order-cancelled {
        background: #ffcdd2;
        color: #c63737;
    }

    &.order-pending {
        background: #feedaf;
        color: #8a5340;
    }

    &.order-returned {
        background: #eccfff;
        color: #694382;
    }
}

.p-datatable {
    .p-sortable-column {
        &.p-highlight {
            &:hover {
                background: rgb(216 217 243);
                color: #464df2;
            }
        }
    }
}

.true-icon {
    color: #256029;
}

.false-icon {
    color: #c63737;
}

.w-100 {
    width: 100%;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-help.p-button-outlined:hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}

.p-button.p-button-danger.p-button-outlined:hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:hover {
    background-color: transparent;
    color: #171718;
    border: 0px solid;
}
</style>